import { css } from '@emotion/react';
import { theme } from '@web/features/theme';

export const base64PlayingIcon =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMyIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDMzIDI0Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTEyLDZBMywzLDAsMCwwLDksOVYyMWEzLDMsMCwwLDAsNiwwVjlBMywzLDAsMCwwLDEyLDZaIi8+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xMiw2QTMsMywwLDAsMCw5LDlWMjFhMywzLDAsMCwwLDYsMFY5QTMsMywwLDAsMCwxMiw2WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTgpIi8+PHBhdGggY2xhc3M9ImEiIGQ9Ik0yMSwwYTMsMywwLDAsMC0zLDNWMjFhMywzLDAsMCwwLDYsMFYzQTMsMywwLDAsMCwyMSwwWiIvPjxwYXRoIGNsYXNzPSJhIiBkPSJNMywxMmEzLDMsMCwwLDAtMywzdjZhMywzLDAsMSwwLDYsMFYxNWEzLDMsMCwwLDAtMy0zWiIvPjwvc3ZnPg==';
export const base64CheckmarkIcon =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMy45MjYiIGhlaWdodD0iMTcuMjU5IiB2aWV3Qm94PSIwIDAgMjMuOTI2IDE3LjI1OSI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xLjU0MSwxNi40NzFsNC41Niw0LjU2YTMuMjg0LDMuMjg0LDAsMCwwLDQuNjQzLDBMMjQuODI2LDYuOTQ4YTEuNTYsMS41NiwwLDAsMCwwLTEuODk1LDEuODcyLDEuODcyLDAsMCwwLTIuMSwwTDkuMzI1LDE4LjM0OGExLjgwNywxLjgwNywwLDAsMS0yLjA1NCwwTDMuNzU0LDE0LjVjLS40MjctLjQyOC0xLjc4Ni0uNzI4LTIuMjEzLS4zYTIuMTc1LDIuMTc1LDAsMCwwLDAsMi4yNzNaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMS4yMiAtNC43MzMpIi8+PC9zdmc+';

export const styles = {
  formWrapper: css`
    max-width: ${theme.space.md};
    margin: ${theme.space[6]} auto;
    border-radius: ${theme.radii.lg};

    &.submitting {
      * {
        opacity: 0.85;
        pointer-events: none;
      }

      button {
        background-color: ${theme.colors.muted};
      }
    }
  `,

  formStatus: css`
    margin: ${theme.space[2]} auto;
    padding: ${theme.space[2]};
    text-align: center;
    border: ${theme.borders['2px']};
    border-radius: ${theme.radii.full};
    border-color: transparent;

    &.default {
      border: none;
    }
    &.error {
      border-color: ${theme.colors.error};
    }
    &.success {
      border-color: ${theme.colors.success};
    }
    &.info {
      border-color: ${theme.colors.info};
    }
    &.warning {
      border-color: ${theme.colors.warning};
    }
  `,

  formField: css`
    position: relative;
    margin: ${theme.space[4]} auto;
    padding: 0 5%;

    & > * {
      width: 100%;
      text-align: left;
    }

    &.has-error {
      input,
      select,
      label {
        border-color: ${theme.colors.error};
      }
    }

    &.small {
      max-width: 20%;
    }

    &.medium {
      max-width: 40%;
    }

    &.large {
      max-width: 65%;
    }

    &.full {
      max-width: 100%;
    }

    &.checkbox {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      justify-content: flex-start;

      label {
        margin-bottom: 0;
      }
    }
  `,

  formLabel: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: ${theme.space[2]};
    font-weight: ${theme.fontWeights.bold};

    &.checkbox {
      height: auto;
      width: auto;
      opacity: 1;
      /* width: 100%; */
    }
  `,

  formError: css`
    color: ${theme.colors.error};
    font-size: ${theme.fontSizes.xs};
  `,

  formInput: css`
    appearance: auto;
    padding: ${theme.space[3]} ${theme.space[4]};
    color: ${theme.colors.whiteAlpha['900']};
    background-color: ${theme.colors.blackAlpha['400']};
    border: ${theme.borders['2px']};
    border-radius: ${theme.radii.full};
    border-color: ${theme.colors.whiteAlpha['600']};
    box-shadow: ${theme.shadows.lg};
    color: ${theme.colors.info};

    &::placeholder,
    .placeholder {
      color: ${theme.colors.info};
    }

    &[type='checkbox'] {
      order: -1;
      width: auto;
      margin-right: ${theme.space[4]};
    }

    &:focus,
    &:not(:placeholder-shown) {
      background-color: ${theme.colors.whiteAlpha['900']};
      color: ${theme.colors.blackAlpha['900']};

      &::placeholder {
        color: ${theme.colors.info};
      }
    }

    &:not(:placeholder-shown):not(.has-error) {
      border-color: ${theme.colors.success};
    }
  `,

  formActionsWrapper: css`
    flex: 1 0 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
  `,

  formAction: css`
    padding: ${theme.space[2]} ${theme.space[16]};
    margin: ${theme.space[2]} ${theme.space[4]};
  `,

  /** Custom Input Styles */
  imageUploadInput: css`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin: ${theme.space[4]} auto;
    display: flex;
    width: 100%;

    &.has-error {
      input,
      select,
      label {
        border-color: ${theme.colors.error};
      }
    }

    p {
      flex: 1 1 100%;
    }

    input {
      opacity: 0;
      width: 0.1px;
      height: 0.1px;
      position: absolute;
    }

    label {
      cursor: pointer;
      display: block;
      margin: 0 auto;
      position: relative;
      padding: ${theme.space[8]} ${theme.space[12]};
      max-width: ${theme.space.xs};
      max-height: ${theme.space.xs};
      border-radius: ${theme.radii.lg};
      background: ${theme.colors.blackAlpha[600]};
      border: ${theme.borders['2px']} ${theme.colors.whiteAlpha[700]};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .preview {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: ${theme.radii.lg};
        z-index: -1;
      }

      svg {
        height: ${theme.space[24]};
        width: ${theme.space[24]};
        fill: ${theme.colors.info};
        margin-bottom: ${theme.space[4]};
      }
    }
  `,

  formRightIcon: css`
    position: absolute;
    top: 35%;
    right: 10%;
    bottom: 0;

    margin: auto;
    height: 75%;
    width: 10%;

    fill: ${theme.colors.info};
    background: transparent;
    border: none;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
      height: ${theme.space[6]};
      width: ${theme.space[6]};
    }
  `,
};
