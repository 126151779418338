import * as React from 'react';

export function SvgPersonTime(
  props: { tversion?: 1 | 2 } & React.SVGProps<SVGSVGElement> = { tversion: 1 },
) {
  switch (props.tversion) {
    default:
    case 1: {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 19.2 19.2"
          {...props}
        >
          <path d="M13.6 8a5.6 5.6 0 105.6 5.6A5.6 5.6 0 0013.6 8zm2.166 7.767a.8.8 0 01-1.131 0l-1.6-1.6a.8.8 0 01-.235-.567v-2.4a.8.8 0 011.6 0v2.069l1.366 1.366a.8.8 0 01.003 1.134zM6.781 8.8a4.4 4.4 0 114.4-4.4 4.4 4.4 0 01-4.4 4.4zm2.306 10.4H.8a.8.8 0 01-.8-.809 8.222 8.222 0 015.243-7.682 5.349 5.349 0 011.916-.307 7.271 7.271 0 00-.339.8A7.084 7.084 0 006.4 13.6a7.188 7.188 0 002.685 5.6z" />
        </svg>
      );
    }

    case 2: {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64.014 64.014"
          {...props}
        >
          <g transform="translate(-173 -306)">
            <path
              d="M45.343,26.672A18.671,18.671,0,1,0,64.014,45.343,18.671,18.671,0,0,0,45.343,26.672Zm7.22,25.891a2.667,2.667,0,0,1-3.771,0l-5.334-5.334a2.667,2.667,0,0,1-.782-1.886v-8a2.667,2.667,0,1,1,5.334,0v6.9l4.553,4.553A2.667,2.667,0,0,1,52.563,52.563ZM22.6,29.34a14.67,14.67,0,1,1,14.67-14.67A14.67,14.67,0,0,1,22.6,29.34Zm7.687,34.674H2.667A2.667,2.667,0,0,1,0,61.317,27.406,27.406,0,0,1,17.479,35.712a17.831,17.831,0,0,1,6.385-1.024h0a24.236,24.236,0,0,0-1.131,2.654,23.613,23.613,0,0,0-1.395,8,23.96,23.96,0,0,0,8.951,18.671Z"
              transform="translate(173 306)"
              fill="#f4d1ae"
            />
            <path
              d="M45.343,18.333A18.671,18.671,0,1,0,64.014,37,18.671,18.671,0,0,0,45.343,18.333Zm7.22,25.891a2.667,2.667,0,0,1-3.771,0L43.457,38.89A2.667,2.667,0,0,1,42.676,37V29a2.667,2.667,0,1,1,5.334,0v6.9l4.553,4.553A2.667,2.667,0,0,1,52.563,44.224ZM30.289,55.675H2.667A2.667,2.667,0,0,1,0,52.978,27.406,27.406,0,0,1,17.479,27.373a17.831,17.831,0,0,1,6.385-1.024h0A24.236,24.236,0,0,0,22.733,29a23.613,23.613,0,0,0-1.395,8,23.96,23.96,0,0,0,8.951,18.671Z"
              transform="translate(173 314.339)"
              fill="#06bcc1"
            />
            <path
              d="M38.216,18.333A18.671,18.671,0,1,0,56.887,37,18.671,18.671,0,0,0,38.216,18.333Zm7.22,25.891a2.667,2.667,0,0,1-3.771,0L36.33,38.89A2.667,2.667,0,0,1,35.549,37V29a2.667,2.667,0,1,1,5.334,0v6.9l4.553,4.553A2.667,2.667,0,0,1,45.436,44.224Zm-28.7-17.876C16.308,27.21,14.564,26.3,16.737,26.348Z"
              transform="translate(180.127 314.339)"
              fill="#49306b"
            />
          </g>
        </svg>
      );
    }
  }
}

export function SvgMeditationPerson(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 53.441 62.97"
      {...props}
    >
      <path
        fill="#f4d1ae"
        d="M36.87 9.942a9.943 9.943 0 11-9.943-9.943 9.942 9.942 0 019.943 9.943zm0 0"
      />
      <path
        d="M46.814 49.713h-1.75c-11.507 0-24.765 13.256-24.765 13.256h26.514a6.628 6.628 0 100-13.256zm0 0"
        fill="#49306b"
      />
      <path
        fill="#06bcc1"
        d="M9.823 28.93L8.234 46.401h.557a16.684 16.684 0 014.631.652 42.177 42.177 0 0113.3 6.4c5.142-3.54 11.877-7.057 18.343-7.057h.557L44.033 28.93a9.9 9.9 0 00-9.9-9.042H19.724a9.9 9.9 0 00-9.901 9.042zm0 0"
      />
      <path
        d="M8.38 49.713H6.63a6.628 6.628 0 100 13.256h8.984l2.343-2.343a65.243 65.243 0 015.918-5.1c-4.569-3.021-10.215-5.813-15.495-5.813zm0 0"
        fill="#49306b"
      />
    </svg>
  );
}

export function SvgCamera(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64.864 64.864"
      {...props}
    >
      <path d="M47.891 8.108l-3.816-4.951A8.17 8.17 0 0037.654 0H27.21a8.17 8.17 0 00-6.419 3.157L16.97 8.108z" />
      <circle cx="11" cy="11" r="11" transform="translate(21.432 26.238)" />
      <path d="M51.351 13.513H13.513A13.53 13.53 0 000 27.026V51.35a13.53 13.53 0 0013.513 13.514h37.838A13.53 13.53 0 0064.864 51.35V27.026a13.53 13.53 0 00-13.513-13.513zm-18.919 40.54a16.216 16.216 0 1116.216-16.216 16.216 16.216 0 01-16.216 16.216z" />
    </svg>
  );
}
