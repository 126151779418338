/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-nested-ternary */
import { Button } from '@web/components/button';
import {
  listAnimation,
  listChildAnimation,
  variantProps,
} from '@web/features/theme';
import { motion } from 'framer-motion';
import * as React from 'react';
import {
  DeepPartial,
  FieldValues,
  FormProvider as RHFProvider,
  SubmitHandler,
  UnpackNestedValue,
  useForm,
} from 'react-hook-form';
import { styles } from './form.styles';

type FormSchema = Record<string, {} | undefined | null>;

export type FormSubmit<Schema extends FieldValues> = SubmitHandler<Schema>;

export type FormProviderProps<Schema extends FormSchema = FormSchema> = {
  children?: Array<
    React.ReactChild & { props: { name: string }; type: unknown }
  >;

  defaultValues: UnpackNestedValue<DeepPartial<Schema>>;
  onSubmit: FormSubmit<Schema>;
  submitButtonText?: string;
  status?: FormStatus;
  submitButton?: React.ReactNode;
  withReset?: boolean;
  withCancel?: false | (() => unknown);
};

export type FormStatus = {
  type: 'default' | 'error' | 'success' | 'info' | 'warning';
  el: string | JSX.Element;
};

export const validations = {
  required: {
    value: true,
    message: 'Required',
  },

  minLength: {
    value: 2,
    message: 'Too short (2)',
  },

  maxLength: {
    value: 24,
    message: 'Too long (2)',
  },

  passwordMinLength: {
    value: 6,
    message: 'Password must be at least 6 characters long.',
  },

  email: {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Hmm, that doesn't look like a correct email.",
  },
};

/**
 * # FormProvider
 *
 * Used throughout the app to create forms
 *
 * @remarks
 * Generally has Input inside to build the form
 * Can be used with {@link useFormStatus} to provide status updates
 *
 * @bug multiple children are required. Sometimes you'll see an empty `<div/>`
 *  Don't remove unless this get refactored
 */
export function FormProvider<Schema extends FormSchema = FormSchema>({
  status,
  defaultValues,
  onSubmit,
  submitButtonText,
  children,
  submitButton,
  withReset = false,
  withCancel = false,
}: FormProviderProps<Schema>) {
  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    shouldUnregister: false,
  });

  return (
    <RHFProvider {...methods}>
      <motion.form
        onSubmit={methods.handleSubmit(onSubmit)}
        css={styles.formWrapper}
        className={`rr-ignore ${
          methods.formState.isSubmitting ? 'submitting' : 'idle'
        }`}
        variants={listAnimation}
        {...variantProps}
      >
        {status ? (
          typeof status.el === 'string' ? (
            <motion.p
              css={styles.formStatus}
              className={status.type}
              variants={listChildAnimation}
            >
              {status.el}
            </motion.p>
          ) : (
            <motion.div
              css={styles.formStatus}
              className={status.type}
              variants={listChildAnimation}
            >
              {status.el}
            </motion.div>
          )
        ) : null}

        {children}

        <motion.div
          css={styles.formActionsWrapper}
          variants={listChildAnimation}
        >
          {withCancel ? (
            <Button
              type="button"
              onClick={withCancel}
              css={styles.formAction}
              variants={listChildAnimation}
              className="muted"
            >
              Cancel
            </Button>
          ) : null}

          {withReset ? (
            <Button
              type="reset"
              onClick={() => methods.reset()}
              css={styles.formAction}
              variants={listChildAnimation}
              className="error"
            >
              Reset
            </Button>
          ) : null}

          {submitButton ?? (
            <Button
              type="submit"
              className="info"
              css={styles.formAction}
              variants={listChildAnimation}
            >
              {submitButtonText ?? 'Submit'}
            </Button>
          )}
        </motion.div>
      </motion.form>
    </RHFProvider>
  );
}

/**
 * # useFormStatus
 *
 * Used to provide status updates to a {@link FormProvider}.
 *
 * @export
 * @param {FormStatus} [defaultStatus]
 * @return {[status, setStatus]}
 */
export function useFormStatus(defaultStatus?: FormStatus) {
  const [status, setStatus] = React.useState<FormStatus | undefined>(
    defaultStatus,
  );

  return [status, setStatus] as const;
}
